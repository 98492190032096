import { Modal, FormControl, FormGroup, ControlLabel, Button } from 'react-bootstrap';
import { Component } from 'react';
import { ROOT_URL } from './constants'

const URL = `${ROOT_URL}/reservations/cancel`;

export default class CancelReservationModal extends Component {

  state= {
    email: '',
    submitted: false,
    error: false
  };

  submitEmail = () => {
    setTimeout(() => {
      fetch(`${URL}`, {
        method:'POST',
        mode: 'cors',
        body: this.state.email
      })
        .then(res => {
          if(res.ok) {
            this.setState({submitted: true})
          } else {
            throw new Error();
          }
        })
        .catch(err => this.setState({error: true}))
    }, 1000);
  };

  resetState = () => {
    this.setState({
      email:'',
      submitted:false,
      error:false
    })
  };

  handleChange = (e) => {
    this.setState({[e.target.name]: e.target.value});
  };

  renderConditionalContent(condition) {
    switch(condition) {
      case "error":
        return (
          <Modal onExited={this.resetState} show={this.props.show} onHide={this.props.toggleModal}>
            <div className="reservation-cancel-modal">
              <Modal.Header>
                <Modal.Title>Tapahtui virhe</Modal.Title>
              </Modal.Header>
              <Modal.Body className="topic-list-modal-body">
                <p>Sähköpostiosoitteelle ei löytynyt olemassa olevaa varausta.</p>
                <p>Tarkista onko sähköpostiosoite kirjoitettu oikein. Mikäli kyseessä on virhe, ota yhteys palvelun ylläpitäjään.</p>
              </Modal.Body>
              <Modal.Footer>
                <Button onClick={this.props.toggleModal}>Sulje</Button>
              </Modal.Footer>
            </div>
          </Modal>
        );
      case "submitted":
        return (
          <Modal onExited={this.resetState} show={this.props.show} onHide={this.props.toggleModal}>
            <div className="reservation-cancel-modal">
              <Modal.Header>
                <Modal.Title>Pyyntö lähetetty</Modal.Title>
              </Modal.Header>
              <Modal.Body className="topic-list-modal-body">
                <p>Annettuun sähköpostiosoitteeseen on lähetetty ohjeet varauksen perumiseen.</p>
              </Modal.Body>
              <Modal.Footer>
                <Button onClick={this.props.toggleModal}>Sulje</Button>
              </Modal.Footer>
            </div>
          </Modal>
        );
      case "default":
        return (
          <Modal onExited={this.resetState} show={this.props.show} onHide={this.props.toggleModal}>
            <div className="reservation-cancel-modal">
              <Modal.Header>
                <Modal.Title>Varauksen peruminen</Modal.Title>
              </Modal.Header>
              <Modal.Body className="topic-list-modal-body">
                <p>Peruuta varauksesi syöttämällä sähköpostiosoitteesi alla olevaan kenttään.</p>
                <FormGroup className="topic-list-modal-form">
                  <ControlLabel>Sähköposti</ControlLabel>
                  <FormControl onChange={this.handleChange} name="email" value={this.state.email} style={{maxWidth:`${200}px`}} />
                </FormGroup>
              </Modal.Body>
              <Modal.Footer>
                <Button onClick={this.props.toggleModal}>Sulje</Button>
                <Button onClick={this.submitEmail} className="btn-oppa-danger btn">Lähetä</Button>
              </Modal.Footer>
            </div>
          </Modal>
        );
    }
  }


  render() {
    return (
      <div aria-live="polite" aria-atomic="true">
        {this.state.error ? this.renderConditionalContent("error") : null}
        {this.state.submitted ? this.renderConditionalContent("submitted") : null}
        {this.state.error || this.state.submitted ? null : this.renderConditionalContent("default")}
      </div>
    );
}}
