import { Component } from 'react';
import { Row, Button, FormControl, ControlLabel, Nav, NavDropdown, MenuItem} from 'react-bootstrap';
import CancelReservationModal from "./CancelReservationModal";
import MultiSelectFilter from "./MultiSelectFilter";


export default class FilterBar extends Component {

  state = {
    show: false
  };

  toggleModal = () => {
    this.setState((prevState) => ({ show: !prevState.show }));
  };

  handleSelectToParent = (selected, name) => {
    this.props.handleSelect(selected, name);
  };

 //<ControlLabel>Haku</ControlLabel>

  render() {
    return(
      <Row className="filter-bar-row">
        <div className="filter-bar">
          <div className="filter-bar-filters">

            <FormControl
              aria-label="Vapaasanahaku"
              name="Haku"
              title="Haku"
              aria-placeholder="Haku"
              placeholder="Haku"
              onChange={this.props.handleSearch}
              style={{maxWidth:`${150}px`}}
              type="text" />

            <div className="filter-bar-filters-wrapper">
              <MultiSelectFilter
                isFilter={true}
                name="expertise"
                onChange={this.handleSelectToParent}
                data={this.props.expertise}
              />
            </div>
            <div className="filter-bar-filters-wrapper">
              <MultiSelectFilter
                isFilter={true}
                name="dissertationLevel"
                onChange={this.handleSelectToParent}
                data={this.props.dissertation}
              />
            </div>

            <Button className="btn-oppa-link btn" onClick={this.toggleModal}>Varauksen peruuttaminen</Button>
          </div>
        </div>
        <CancelReservationModal
          toggleModal={this.toggleModal}
          show={this.state.show}
        />
      </Row>
    );
  }
}
