import { Component } from 'react';
import { connect }from 'react-redux';
import { Redirect } from 'react-router-dom';
import { Link } from 'react-router-dom';
import Header from '../Header';
import _ from 'lodash';
import { Button, Grid, Row } from 'react-bootstrap';
import {fetchReservation} from "../actions/fetchReservation";
import {clearCancelReservation} from "../actions/clearCancelReservation";
import {cancelReservation} from "../actions/cancelReservation";
import ConfirmActionModal from "../ConfirmActionModal";
import Loader from "../Loader";


class CancelReservation extends Component {

  state = {
    show: false
  };

  componentWillMount() {
    document.title = "Varauksen peruminen - Opinnäytetyöaiheet";
    this.props.fetchReservation(this.props.id);
  }

  handleReservationCancel = () => {
    this.setState({show:false}, () => {
      this.props.cancelReservation(this.props.reservationData.data.reservationId);
    });
  };

  componentWillUnmount() {
    this.props.clearCancelReservation();
  }

  render() {
    if(_.isEmpty(this.props.reservationData.data) && !this.props.reservationData.removed) {
      return(
        <div>
          <Header />
          <Loader />
        </div>
      );
    }

    if(this.props.reservationData.removed) {
      return (
        <Redirect
          push
          to={{
          pathname: `/`
        }}
        />
      );
    }

    if(_.has(this.props.reservationData.data, 'error')) {
      return(
        <div>
          <Header />
          <Grid>
            <Row>
              <div className="topic-info-container">
                <div className="topic-info-container-inner">
                  <div className="topic-info-modal">
                    <div className="topic-info-modal-header">
                      <h4>Tapahtui virhe.</h4>
                    </div>
                    <div className="topic-info-modal-content">
                      <p>Tietojen hakeminen epäonnistui..</p>
                    </div>
                    <div className="topic-info-modal-buttons">
                      <Link to={{pathname: '/'}}>
                        <Button className="btn-oppa-primary btn">Takaisin etusivulle</Button>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </Row>
          </Grid>
        </div>
      );
    }

    return (
      <div>
        <Header />
        <Grid className="oppa-content-container">
          <Row>
            <div className="topic-info-container">
              <div className="topic-info-container-inner">
                <div className="topic-info-modal">
                  <div className="topic-info-modal-header">
                    <h1>{this.props.reservationData.data.topic.title}</h1>
                  </div>
                  <div className="topic-info-modal-content">
                    <h2>Tutkimuksen ja kehittämisen painopistealue</h2>
                    <p>{this.props.reservationData.data.topic.focus}</p>
                    <h2>Toimialue</h2>
                    <p>{this.props.reservationData.data.topic.domain.map((dom, i) => <p key={i}>{dom.name}</p>)}</p>
                    <h2>Vastuualue</h2>
                    <p>{this.props.reservationData.data.topic.areaOfResponsibility.map((aor, i) => <p key={i}>{aor.name}</p>)}</p>
                    <h2>Yksikkö</h2>
                    <p>{this.props.reservationData.data.topic.department.map((dep, i) => <p key={i}>{dep.name}</p>)}</p>
                    <h2>Opinnäytetyön taso</h2>
                    {this.props.reservationData.data.topic.dissertationLevel.map((dis, i) => <p key={i}>{dis.label}</p>)}
                    <h2>Aihealue</h2>
                    {this.props.reservationData.data.topic.expertise.map((exp, i) => <p key={i}>{exp.label}</p>)}
                    <h2>Työelämän tarve opinnäytetyölle</h2>
                    <p>{this.props.reservationData.data.topic.details}</p>
                    <h2>Työelämän ehdotus aineistonkeruun menetelmästä</h2>
                    <p>{
                      this.props.reservationData.data.topic.researchMethod !== undefined
                      && this.props.reservationData.data.topic.researchMethod.length > 0
                        ? this.props.reservationData.data.topic.researchMethod
                        : "-"
                    }</p>
                    <h2>Työelämän yhteyshenkilö</h2>
                    <p>{this.props.reservationData.data.topic.officialContact}</p>
                    <hr />
                    <h2>Varauksen tiedot</h2>
                    {this.props.reservationData.data.students.map((student, index) => {
                      if(student.firstName.length > 0) {
                        return (<div>
                          <h2>Opiskelija {index + 1}</h2>
                          <p>{student.firstName}</p>
                          <p>{student.lastName}</p>
                          <p>{student.email}</p>
                          <p>{student.fieldOfStudy}</p>
                        </div>);
                      }
                    })}
                    <h2>Ohjaava opettaja</h2>
                    <p>{this.props.reservationData.data.teacher !== undefined ? this.props.reservationData.data.teacher.firstName : ""}</p>
                    <p>{this.props.reservationData.data.teacher !== undefined ? this.props.reservationData.data.teacher.lastName : ""}</p>
                    <p>{this.props.reservationData.data.teacher !== undefined ? this.props.reservationData.data.teacher.email : ""}</p>
                  </div>
                  <div className="topic-info-modal-buttons">
                    <Button onClick={() => this.setState({show: true})} className="btn-oppa-danger btn">Peruuta varaus</Button>
                    <Link to={{pathname: `/`}}>
                      <Button>Palaa etusivulle</Button>
                    </Link>
                  </div>
                </div>
                <ConfirmActionModal
                  toggleModal={() => {this.setState((prevState) => ({show:!prevState.show}))}}
                  modalAction={this.handleReservationCancel}
                  title="Olet peruuttamassa varauksen!"
                  message="Haluatko varmasti peruuttaa varauksen?"
                  action="Peruuta varaus"
                  show={this.state.show}
                />
              </div>
            </div>
          </Row>
        </Grid>
      </div>
    );
  }

}

function mapStateToProps(state) {
  return {
    reservationData: state.cancelData
  }
}

export default connect(mapStateToProps, { fetchReservation, cancelReservation, clearCancelReservation })(CancelReservation);
