import { Component } from 'react';
import {Row} from 'react-bootstrap';
import {RESERVATION_GUIDE_URL} from "./constants";

export default class TopicListTitle extends Component {
  render() {
    return (
      <Row className="topic-list-title">
        <h1>Opinnäytetyöaihepankki</h1>
        <p>
          Tervetuloa tutustumaan opinnäytetyöaihetarjontaamme!
          Tähän aihepankkiin on koottu kaikki Taysissä tarjolla olevat ajankohtaiset opinnäytetöiden aiheet.
          Huomioithan, että varauksen voi tehdä vasta siinä vaiheessa kun opinnäytetyön tekeminen on opintojesi
          vaiheen mukaan ajankohtaista.
        </p>
        <p>Löydät tarkemmat varausohjeet alla olevasta linkistä:</p>
        <a target="_blank" rel="noopener noreferrer" href={`${RESERVATION_GUIDE_URL}`}>Varausohjeet</a>
      </Row>
    );
  }
}
