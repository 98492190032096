import { Component } from 'react';
import {connect} from "react-redux";
import { Grid, Row} from 'react-bootstrap';
import {fetchTopics} from "./actions/fetchTopics";
import {clearReservation} from "./actions/clearReservation";
import {clearTopic} from "./actions/clearTopic";
import TopicList from "./TopicList";
import FilterBar from "./FilterBar";
import Header from './Header';
import TopicListTitle from './TopicListTitle';
import Loader from "./Loader";

class ContainerA extends Component {
  state = {
    active: 1,
    perPage: 5,
    searchTerm: '',
    expertise: [],
    dissertationLevel: [],
    results: 0,
    loading:this.props.topics.loading
  };

  handleSearch = (e) => {
    this.setState({
      searchTerm: e.target.value,
      active: 1
    });
  };

  handleSelect = (selected, name) => {
    this.setState({active: 1}, () => {
      name === 'dissertationLevel'
        ? this.setState({dissertationLevel: selected})
        : this.setState({expertise: selected});
    });

  };

  componentDidMount() {
    this.props.clearReservation();
    this.props.clearTopic();
    this.props.fetchData(false);
    window.scrollTo(0, 0);
    document.title = "Etusivu - Opinnäytetyöaiheet";
  }

  handlePagination = (e) => {
    if(e.target.tagName.toLowerCase() !== 'span') {
      this.setState({active: Number(e.target.text)});
    }
    window.scrollTo(0, 0);
  };

  handlePaginationNext = (e) => {
    if(this.state.active + 1 < this.props.topics.data.length / 5
      || this.state.active + 1 <= this.props.topics.data.length / 5 && this.props.topics.data.length % 5 !== 0) {
      this.setState(prevState => ({ active: prevState.active + 1 }));
    }
    window.scrollTo(0, 0);
  };

  handlePaginationPrev = (e) => {
    if(this.state.active - 1 !== 0) {
      this.setState(prevState => ({
          active: prevState.active - 1
        }))
    }
    window.scrollTo(0, 0);
  };

  handlePaginationFirst = (e) => {
    this.setState({active: 1});
    window.scrollTo(0, 0);
  };

  handlePaginationLast = (e) => {
    if(this.props.topics.data.length % 5 !== 0) {
      this.setState({active: Math.ceil(this.props.topics.data.length / 5)});
    } else {
      this.setState({active: this.props.topics.data.length / 5});
    }
    window.scrollTo(0, 0);
  };

  renderList = () => {

    if(!_.has(this.props.topics.data, "error") && this.props.topics.data.length === 0 && !this.props.topics.finished) {
      return <Loader />
    }

    return (
      <TopicList
        searchTerm={this.state.searchTerm}
        expertise={this.state.expertise}
        dissertation={this.state.dissertationLevel}
        topics={this.props.topics.data}
        active={this.state.active}
        perPage={this.state.perPage}
        handlePagination={this.handlePagination}
        handlePaginationNext={this.handlePaginationNext}
        handlePaginationPrev={this.handlePaginationPrev}
        handlePaginationFirst={this.handlePaginationFirst}
        handlePaginationLast={this.handlePaginationLast}
        editing={0}
      />
    );
  };

  render() {
    if(_.has(this.props.topics.data, "error")) {
      return (
        <div>
          <Header />
          <Grid>
            <Row>
              <div className="topic-info-container">
                <div className="topic-info-container-inner">
                  <div className="topic-info-modal">
                    <div className="topic-info-modal-header">
                      <h4>Yhteysvirhe</h4>
                    </div>
                    <div className="topic-info-modal-content">
                      <p>Palveluun ei juuri nyt saada yhteyttä. Yritä myöhemmin uudelleen.</p>
                    </div>
                    <div className="topic-info-modal-buttons" />
                  </div>
                </div>
              </div>
            </Row>
          </Grid>
        </div>
      );
    }

    return (
      <div>
        <Header />
        <Grid style={{marginBottom:'20px'}} />
        <Grid className="oppa-content-container">
          <TopicListTitle />
          <FilterBar
            handleSearch={this.handleSearch}
            handleSelect={this.handleSelect}
            dissertation={this.state.dissertationLevel}
            expertise={this.state.expertise}
          />
          {this.renderList()}
        </Grid>
      </div>
    );
  }

}

function mapStateToProps(state) {
  return { topics: state.topics };
}


export default connect(mapStateToProps, { fetchData: fetchTopics, clearReservation, clearTopic })(ContainerA);
