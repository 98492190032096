/* eslint import/no-webpack-loader-syntax: "off" */
/* eslint import/no-unresolved: "off" */
import '!style-loader!css-loader!sass-loader!../styles/index.scss';
import { render } from 'react-dom';
import PortletA from '../components/PortletA';
import Footer from '../components/Footer';
import { HashRouter } from 'react-router-dom';

// render(<PortletA />, document.body.appendChild(document.createElement('div')));
render(<PortletA />, document.getElementById("oppaPortletA"));

window.onload = function() {
  render(<HashRouter><Footer /></HashRouter>, document.getElementById("footer"));
};

